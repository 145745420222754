var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-col lg:flex-row flex gap-10"},[_c('div',{staticClass:"flex flex-col gap-2 items-start",staticStyle:{"max-width":"270px","height":"290px"}},[_c('vue-cal',{staticClass:"vuecal--rounded-theme vuecal--egs-theme",attrs:{"xsmall":"","time":false,"hide-view-selector":"","active-view":"month","disable-views":['years', 'year', 'week', 'day'],"max-date":_vm.maxDate,"selectedDate":_vm.selectedDate,"events":_vm.events,"locale":this.de},on:{"cell-focus":function($event){_vm.selectedDate = $event},"view-change":_vm.handleViewChange},scopedSlots:_vm._u([{key:"events-count",fn:function(ref){
var events = ref.events;
return [(_vm.customEventsCount(events))?_c('span',[_vm._v("\n          "+_vm._s(_vm.customEventsCount(events))+"\n        ")]):_c('div')]}}])}),_vm._v(" "),_c('button',{staticClass:"text-sm text-gray-500 hover:text-gray-800 transition-colors duration-200",on:{"click":function($event){_vm.selectedDate = new Date()}}},[_vm._v("zurücksetzen\n    ")])],1),_vm._v(" "),_c('vue-cal',{ref:"vuecal",attrs:{"active-view":"day","disable-views":['years', 'year', 'month', 'week'],"hide-view-selector":"","selectedDate":_vm.selectedDate,"time-from":_vm.timeFrom * 60,"time-to":_vm.timeTo * 60,"time-step":_vm.timeStep,"events":_vm.events,"timeCellHeight":45,"max-date":_vm.maxDate,"on-event-click":_vm.onEventClick,"locale":this.de},on:{"cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"arrow-prev",fn:function(){return [_c('span')]},proxy:true},{key:"arrow-next",fn:function(){return [_c('span')]},proxy:true},{key:"time-cell",fn:function(ref){
var hours = ref.hours;
var minutes = ref.minutes;
return [_c('div',{class:{ 'vuecal__time-cell-line': true, hours: !minutes }},[(!minutes)?_c('strong',{staticClass:"text-egs-900"},[_vm._v(_vm._s(hours)+":00")]):_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(hours)+":"+_vm._s(minutes))])])]}},{key:"event",fn:function(ref){
var event = ref.event;
return [(!event.background)?_c('div',{staticClass:"flex px-2 h-full cursor-pointer",style:({ backgroundColor: event.color,
                     color: _vm.getContrastColor(event.color) })},[_c('div',{staticClass:"vuecal__event-title text-sm"},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(event.name))]),_vm._v(" - "+_vm._s(event.type)+"\n        ")])]):_c('div',{staticClass:"flex px-2 h-full justify-center items-center"},[_c('div',{staticClass:"vuecal__event-title font-bold",domProps:{"innerHTML":_vm._s(event.title)}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }