<template>
  <div class="flex-col lg:flex-row flex gap-10">
    <div class="flex flex-col gap-2 items-start" style="max-width: 270px; height: 290px">
      <vue-cal
          xsmall
          class="vuecal--rounded-theme vuecal--egs-theme"
          :time="false"
          hide-view-selector
          active-view="month"
          :disable-views="['years', 'year', 'week', 'day']"
          @cell-focus="selectedDate = $event"
          @view-change="handleViewChange"
          :max-date="maxDate"
          :selectedDate="selectedDate"
          :events="events"
          :locale="this.de">

        <template #events-count="{ events }">
          <span v-if="customEventsCount(events)">
            {{ customEventsCount(events) }}
          </span>
          <div v-else></div>
        </template>

      </vue-cal>
      <button class="text-sm text-gray-500 hover:text-gray-800 transition-colors duration-200"
              @click="selectedDate = new Date()">zurücksetzen
      </button>
    </div>

    <vue-cal ref="vuecal"
             active-view="day" :disable-views="['years', 'year', 'month', 'week']"
             hide-view-selector
             :selectedDate="selectedDate"
             :time-from="timeFrom * 60" :time-to="timeTo * 60" :time-step="timeStep"
             :events="events"
             :timeCellHeight="45"
             :max-date="maxDate"
             :on-event-click="onEventClick"
             @cell-click="onCellClick"
             :locale="this.de">

      <template #arrow-prev>
        <span></span>
      </template>

      <template #arrow-next>
        <span></span>
      </template>

      <template #time-cell="{ hours, minutes }">
        <div :class="{ 'vuecal__time-cell-line': true, hours: !minutes }">
          <strong v-if="!minutes" class="text-egs-900">{{ hours }}:00</strong>
          <span v-else style="font-size: 11px">{{ hours }}:{{ minutes }}</span>
        </div>
      </template>

      <template #event="{ event }">
        <div v-if="!event.background" class="flex px-2 h-full cursor-pointer"
             :style="{ backgroundColor: event.color,
                       color: getContrastColor(event.color) }">
          <div class="vuecal__event-title text-sm">
            <span class="font-semibold">{{ event.name }}</span> - {{ event.type }}
          </div>
        </div>
        <div v-else class="flex px-2 h-full justify-center items-center">
          <div class="vuecal__event-title font-bold" v-html="event.title"/>
        </div>
      </template>
    </vue-cal>
  </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import {mapState, mapGetters} from 'vuex'

export default {
  components: {VueCal},
  data: function () {
    return {
      timeStep: 30,
      selectedDate: new Date(),
      de: {
        "weekDays": ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
        "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        "years": "Jahre",
        "year": "Jahr",
        "month": "Monat",
        "week": "Woche",
        "day": "Tag",
        "today": "Heute",
        "noEvent": "Keine Termine",
        "allDay": "Ganztägig",
        "deleteEvent": "Löschen",
        "createEvent": "Termin erstellen",
        "dateFormat": "dddd D MMMM YYYY"
      }
    }
  },
  created() {

  },
  computed: {
    ...mapState({
      timeFrom: state => state.timeFrom,
      timeTo: state => state.timeTo,
    }),
    ...mapGetters([
      'events'
    ]),
    maxDate() {
      return new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    }
  },
  methods: {
    onCellClick(date) {
      // round clicked date down to nearest timeStep
      date.setMinutes(Math.floor(date.getMinutes() / this.timeStep) * this.timeStep)

      const time = `${date.getHours()}:${date.getMinutes()}`
      const dateOpts = {day: '2-digit', month: '2-digit', year: 'numeric'};
      const formattedDate = date.toLocaleDateString(undefined, dateOpts).replace(/\//g, '.');

      const params = new URLSearchParams()
      params.append('appointment[booked_on]', formattedDate)
      params.append('appointment[booked_at]', time)
      const newAppointmentURL = `${window.location.href}/appointments/new?${params.toString()}`
      Turbo.visit(newAppointmentURL, { action: "replace" });
      //window.open(newAppointmentURL)
      //window.location.href = newAppointmentURL
    },
    onEventClick(event) {
      // window.open(`${window.location.href}/appointments/${event.id}`, "_blank")
      const eventUrl = `${window.location.href}/appointments/${event.id}`
      Turbo.visit(eventUrl, { action: "replace" });
    },
    getContrastColor(hexCode) {
      const r = parseInt(hexCode.substr(1, 2), 16);
      const g = parseInt(hexCode.substr(3, 2), 16);
      const b = parseInt(hexCode.substr(5, 2), 16);

      // Calculate the relative luminance of the color
      const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

      if (luminance < 0.5) {
        return '#FFF';
      } else {
        return '#000';
      }
    },
    customEventsCount(events) {
      return events ? events.filter(e => !e.background).length : 0
    },
    handleViewChange(event) {
      if (new Date(event.startDate).getMonth() === new Date().getMonth())
        this.selectedDate = new Date()
      else
        this.selectedDate = event.startDate
    }
  }
}
</script>

<style lang="scss">
.vuecal--rounded-theme.vuecal--egs-theme {
  .vuecal__cell--selected .vuecal__cell-content {
    background-color: #004081;
  }

  .vuecal__cell--selected .vuecal__cell-content {
    border-color: #004081;
    background-color: lighten(#c8eaff, 6);
    color: #222;
  }

  .vuecal__cell--today .vuecal__cell-content {
    background-color: #004081;
    color: white;
  }

  .vuecal__cell-content {
    background-color: lighten(#c8eaff, 6);
  }
}

.vuecal__cell-content {
  cursor: pointer;
}

.vuecal__time-cell-line.hours:before {
  border-color: #7ac7f7;
}

//.vuecal__time-cell-line {
//  height: 100%;
//}

.vuecal__cell--disabled {
  //text-decoration: line-through;
  color: #fff222;
}

//.vuecal__cell--before-min {
//  color: #b6d6c7;
//}

//.vuecal__cell--after-max {
//  color: #008b8b;
//}

.vuecal__event:not(.blocked-time) {
  //transform: scaleY(0.96);
  border-bottom: 1px solid transparent;
}

.vuecal__event-content {
  margin-top: 5px;
}

.vuecal__title-bar {
  background-color: #004081;

  & > * {
    color: white;
  }
}

.vuecal {
  border-radius: 10px;
  overflow: hidden;
}

.vuecal__now-line {
  color: #239a36;
  border-top-width: 3px;
  border-bottom-width: 3px;
  pointer-events: none;
}

.vuecal__view-btn {
  color: #f5f5f5;
}

.vuecal__cell-events-count {
  background: transparent;
}

.vuecal__cell-events-count span {
  background: #004081;
  height: 100%;
  min-width: 12px;
  padding: 0 3px;
  border-radius: 12px;
  display: block;
}

.vuecal__event.blocked-time {
  pointer-events: none;
  background: rgba(255, 247, 240, 0.19) repeating-linear-gradient(
          -45deg,
          rgba(255, 87, 157, 0.12),
          rgba(255, 87, 157, 0.12) 5px,
          rgba(255, 255, 255, 0) 5px,
          rgba(255, 255, 255, 0) 15px
  );
  color: rgba(246, 76, 90, 0.65);
}

</style>
